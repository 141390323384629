@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

@import url('https://fonts.cdnfonts.com/css/source-sans-pro');

@media screen and (max-width: 1280px) {
  .osteonatural-navbar {
    font-size: small;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: lighter;
  font-size: 18px;
  line-height: 1.8em;
}

body a {
  color: #8aac1a;
  text-decoration: none;
}

.grid-container-5 {
  --grid-layout-gap: 15px;
  --grid-column-count: 5;
  --grid-item--min-width: 300px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

.base-page {
  max-width: 1280px;
  margin: 40px auto;
  padding: 30px;
  height: 100%;
}

.osteonatural-navbar {
  background-color: #3a3b3d;
}

.osteonatural-navbar a {
  background-color: #3a3b3d;
  color: white;
  text-decoration: none;
  padding: 10px;
  text-align: center;
}

.osteonatural-navbar a:hover {
  background-color: #8aac1a !important;
}

.osteonatural-footer {
  background-color: #3a3b3d;
  color: lightgray;
  display: flex;
  justify-content: center;
  text-align: center;
}

.osteonatural-subfooter {
  background-color: #353638;
  text-align: center;
}